export var pages = {"en": {"notfound": import.meta.ROLLUP_FILE_URL_7be7167e,
"support-casljs": import.meta.ROLLUP_FILE_URL_0f9a297a,
"advanced/ability-inheritance": import.meta.ROLLUP_FILE_URL_3e491da2,
"advanced/ability-to-database-query": import.meta.ROLLUP_FILE_URL_f2663564,
"advanced/customize-ability": import.meta.ROLLUP_FILE_URL_160d29c0,
"advanced/debugging-testing": import.meta.ROLLUP_FILE_URL_b9e352cb,
"advanced/typescript": import.meta.ROLLUP_FILE_URL_be97c2b3,
"api/casl-ability": import.meta.ROLLUP_FILE_URL_c36fd6ee,
"api/casl-ability-extra": import.meta.ROLLUP_FILE_URL_7b139d95,
"cookbook/cache-rules": import.meta.ROLLUP_FILE_URL_97b3a404,
"cookbook/claim-authorization": import.meta.ROLLUP_FILE_URL_c656f488,
"cookbook/intro": import.meta.ROLLUP_FILE_URL_e968b8af,
"cookbook/less-confusing-can-api": import.meta.ROLLUP_FILE_URL_98dac54e,
"cookbook/roles-with-persisted-permissions": import.meta.ROLLUP_FILE_URL_85142711,
"cookbook/roles-with-static-permissions": import.meta.ROLLUP_FILE_URL_d08bebac,
"guide/conditions-in-depth": import.meta.ROLLUP_FILE_URL_6c1ba520,
"guide/define-aliases": import.meta.ROLLUP_FILE_URL_75e0bd28,
"guide/define-rules": import.meta.ROLLUP_FILE_URL_db617b84,
"guide/install": import.meta.ROLLUP_FILE_URL_140ec40f,
"guide/intro": import.meta.ROLLUP_FILE_URL_52ac2365,
"guide/restricting-fields": import.meta.ROLLUP_FILE_URL_6d2629d3,
"guide/subject-type-detection": import.meta.ROLLUP_FILE_URL_935ddd08,
"package/casl-angular": import.meta.ROLLUP_FILE_URL_9515ba35,
"package/casl-aurelia": import.meta.ROLLUP_FILE_URL_a1fd3245,
"package/casl-mongoose": import.meta.ROLLUP_FILE_URL_e0fe4b47,
"package/casl-prisma": import.meta.ROLLUP_FILE_URL_15556fb7,
"package/casl-react": import.meta.ROLLUP_FILE_URL_4e393c8e,
"package/casl-vue": import.meta.ROLLUP_FILE_URL_3250754d}};
export var summaries = {"en": import.meta.ROLLUP_FILE_URL_56ecc27b};;
export var searchIndexes = {"en": import.meta.ROLLUP_FILE_URL_1cf7d0fb};